import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import "../sass/main.scss"

const IndexPage = ({data}) => (
  <Layout>
    <>
        <GatsbySeo
        	title='Paloma Navascués // Psicóloga // Fotografa'
      		description='Soy Paloma Navascues, una psicologa online especializada en terapia integradora para personas migrantes y expatriadas hispanohablantes'
        >
        </GatsbySeo>
    </>
    {/* <!-- Wrapper --> */}
			<div id="wrapper" className="divided">

				{/* <!-- Banner --> */}
					<section className="banner style1 orient-left content-align-left image-position-center fullscreen onload-image-fade-in onload-content-fade-right">
						<div className="content">
							<h1>Paloma Navascués</h1>
							<p className="major">Soy una psicóloga que ha tenido la gran oportunidad de trabajar en diferentes <strong>áreas de la psicología</strong>. Esta circunstancia me ha aportado una vision global y profunda de las necesidades y obstáculos difíciles con los que se puede encontrar alguien en un momento determinado de su vida.</p>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.heroFluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>



				{/* <!-- Spotlight --> */}
					<section className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="first">
						<div className="content">
							<h2>Psicología online integradora</h2>
							<p>Trabajo desde un <strong>punto de vista integrador</strong>. Entiendo las emociones que experimenta la persona como un reflejo de los esquemas de pensamiento y de relaciones que ha ido construyendo a lo largo de sus experiencias vitales y que se reexperimentan en sus circunstancias actuales.</p>
							<p>Ayudo a la persona a interpretar y reconstruir sus <strong>mapas cognitivos y emocionales</strong> siempre con respeto y aceptación incondicional y, cuando pueden ser convenientes, incorporo técnicas rápidas y efectivas como EMDR y herramientas complementarias como Mindfulness y Fotografía Terapéutica.</p>
							<ul className="actions stacked">
								<li><a href="https://vandla.com/psicologia-online-expatriados/" className="button">Ir a psicología en tu idioma</a></li>
							</ul>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.feat1FluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>

				{/* <!-- Spotlight --> */}
					<section className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
						<div className="content">
							<h2>La fotografía</h2>
							<p>A parte de mi trabajo como terpeuta, otra gran <strong>pasión en mi vida</strong> es la fotografía. Me ha servido como herramienta para entender el mundo y a mi misma desde una perspectiva creativa. Este gran poder de exploración lo he incorporado también en el trabajo terepéutico, tanto de forma individual con en formato de workshop grupales.</p>
							<ul className="actions stacked">
								<li><a href="https://photography.palomanavascues.com" className="button">Ir a fotografías</a></li>
							</ul>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.feat2FluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>


				{/* <!-- Footer --> */}
		          <footer className="wrapper style1 align-center">
		            <div className="inner">
		              <ul className="icons">
		                <li><a href="https://wa.me/34644811385" className="icon style2 fa-whatsapp"><span className="label">Twitter</span></a></li>
		                <li><a href="https://m.me/hellovandla" className="icon style2 fa-facebook"><span className="label">Facebook</span></a></li>
		                <li><a href="mailto:hello@vandla.com" className="icon style2 fa-envelope"><span className="label">Email</span></a></li>
		              </ul>
		              <p>&copy; 2020 Paloma Navascués | <a href="https://vandla.com/politica-privacidad/" rel="nofollow">Politica de Privacidad</a> | Design: <a href="https://html5up.net" rel="nofollow">HTML5 UP</a>.</p>
		            </div>
		          </footer>	

			</div>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    heroFluidImage: file(relativePath: { eq: "psicologa-paloma-navascues.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    feat1FluidImage: file(relativePath: { eq: "spotlight01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    } 
    feat2FluidImage: file(relativePath: { eq: "spotlight02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }                
  }
`
